<template>
	<div class="container-fluid animatedParent animateOnce p-0">
		<!-- main content -->
		<div class="animated fadeInUpShort go">
			<div class="row no-gutters">
				<div class="col-md-3 white sticky">
					<div class="sticky white">
						<ul class="nav nav-tabs nav-material">
							<li class="nav-item">
								<a class="nav-link p-3 active show"><i class="icon icon-user s-18 text-dark"></i>企业用户列表</a>
							</li>
						</ul>
					</div>
					<div class="slimScrollDiv" style="position: relative; overflow: auto; width: auto; ">
						<div class="slimScroll" style="overflow: auto; width: auto; ">
							<div class="tab-content" id="v-pills-tabContent">
								<div class="tab-pane fade show active" id="w2-tab1" role="tabpanel" aria-labelledby="w2-tab1">
									<ul class="list-unstyled ">
										<!-- user item -->
										<li v-bind:key="index" @click="edit(index)" v-for="(item,index) in userlist" :class="[ 'media p-3 b-b ',{'blue lighten-5' : index == selectedIndex}]">
											<img class="d-flex mr-3 height-50" src="../assets/img/dummy/u1.png" alt="用户">
											<div class="media-body text-truncate">
												<small class="float-right">
													<a href="#" class="mr-2 ml-2">
														<i class="icon-circle" v-if="item.enabled==1"></i>
														<i class="icon-circle-o" v-if="item.enabled==0"></i>
													</a>
												</small>
												<h6 class="mt-0 mb-1 font-weight-normal">
												<span v-if="item.phone_number" class="d-block">{{item.phone_number}} </span>
												<span v-if="item.email" class="d-block">{{item.email}} </span>
												
												<!-- <span :class="{'badge badge-pill badge-primary':item.dept_id==0,'badge badge-pill badge-warning':item.dept_id>0}" 
												>{{item.dept_id==0?'平台用户':'企业用户'}}</span> -->
												</h6>
												<template v-if="item.dept_id > 0 && deptmap.hasOwnProperty(item.dept_id)">
													<span>{{deptmap[item.dept_id].department_name}}</span>
												</template>
												
											</div>
										</li>
										<!-- //user item -->
	
										
									</ul>
								</div>
	
							</div>
						</div>
						<div class="slimScrollBar" style="background: rgba(0, 0, 0, 0.95); width: 5px; position: absolute; top: 0px; opacity: 0.4; display: none; border-radius: 7px; z-index: 99; right: 1px; height: 340.981px;"></div>
						<div class="slimScrollRail" style="width: 5px; height: 100%; position: absolute; top: 0px; display: none; border-radius: 7px; background: rgb(51, 51, 51); opacity: 0.2; z-index: 90; right: 1px;"></div>
					</div>
				</div>
				<div class="col-md-9 b-l">
	
					<!-- 编辑区域 -->
					<div class="m-md-3">
						<div class="row my-3">
							<div class="col-md-8 offset-md-2">
								<div class="card no-b">
									<div class="card-body">
										<h5 class="card-title">{{selectedIndex==-1?'添加':'修改'}}用户</h5>
										
										<div>
											<!-- <div class="form-group">
												<label for="inputAddress2" class="col-form-label">用户类型</label>
												<select v-model="user.role_name" class="form-control" style="height:42px;">
													<option value="">选择用户类型</option>
													<option value="ROLE_ADMIN">平台用户</option>
													<option value="ROLE_USER">企业用户</option>
												</select>
												<small class="form-text text-muted">
													
												</small>
											</div> -->
											
											<div class="form-group" v-if="user.role_name == 'ROLE_USER'">
												<label for="inputAddress2" class="col-form-label">所属企业</label>
												<select v-model="user.dept_id" class="form-control">
													<option value="0">选择企业</option>
													<template v-for="(item,index) in departmentlist">
													<option v-bind:key="index"
														:value="item.id"  v-if="item.enabled==1">
														{{item.department_name}}</option>
													</template>
												</select>
											</div>
											
											<div class="form-group">
												<label for="inputAddress" class="col-form-label">手机</label>
												<input autocomplete="off" class="form-control" v-model="user.phone_number" placeholder="手机号码" type="number">
												<small class="form-text text-muted">
													手机或邮箱至少填写一个
												</small>
											</div>
											<div class="form-group">
												<label for="inputAddress" class="col-form-label">邮箱</label>
												<input autocomplete="off" class="form-control" v-model="user.email" placeholder="邮箱地址" type="text">
											</div>
											
											<div class="form-group">
												<label for="inputAddress" class="col-form-label">密码</label>
												<input autocomplete="new-password" class="form-control " v-model="user.password" placeholder="密码"
												 type="password">
												<small v-if="selectedIndex!=-1">不修改请留空</small>
											</div>
											
											
											<div class="form-group" v-if="selectedIndex != -1">
												<div class="form-check">
													<label class="form-check-label">
														<input class="form-check-input" type="checkbox" v-model="user.disabled" value="1"> 禁用
													</label>
												</div>
											</div>
	
											<button v-if="selectedIndex != -1" type="button" :disabled="disabled" class="btn btn-danger" @click.stop="remove">
												删除 </button>
	
											<button type="button" :disabled="disabled" class="btn btn-primary ml-2" @click.stop="save">
												{{selectedIndex == -1?'添加':'修改'}}
											</button>
	
										</div>
	
									</div>
								</div>
							</div>
						</div>
					</div>
					<!-- //编辑区域 -->
	
				</div>
			</div>
		</div>
		<!-- //main content -->
		
		<!--Add New Message Fab Button-->
		<a @click="clear" href="#" class="btn-fab btn-fab-md fab-right fab-right-bottom-fixed shadow btn-secondary"><i class="icon-add"></i></a>
		
	</div>
</template>

<script>
	module.exports = {
		data(){
			return{
				title: '用户',
				
				token: '',
				dept_id: '',
				
				userlist: [],
				user: {
					disabled: false,
					phone_number: '',
					email: '',
					dept_id: 0,
					role_name: 'ROLE_USER' //只加B企业用户
				},
				selectedIndex: -1,
				
				disabled: false,
				isFirst: false,
				
				
				////
				departmentlist: [],
				deptmap: {}, //key为 dept id, value为 dept
			}
		},
		
		created: function() {
			console.log('--- created --- ')
			let self = this;
			
			//1.检查登录
			let token = localStorage.getItem('TOKEN');
			if (!token) {
				return;
			}
			self.token = token;
			
			
		},
		mounted: function() {
			console.log('--- mounted --- ')
			let self = this;
			
			//1.检查登录
			// let token = localStorage.getItem('TOKEN');
			if (!self.token) {
				console.log('--- mounted jump  --- ')
				location.href = 'index.html';
				return;
			}
			
			self.init(() => {
				self.initData();
			});
			
			//收银员列表滚动自适应
			$('.slimScrollDiv, .slimScroll').height($(window).height()-120)
		},
		methods: {
			initData(){
				let self = this;
				
				//调用接口
				self.axios.get(self.GLOBAL.baseURI + 'getEntUserList', {
					params: {
						token: self.token,
						dept_id: self.dept_id,
					}
				})
				.then(function(response) {
					console.log(response);
					return response.data;
				})
				.catch(function(error) {
					console.log(error);
				})
				.then(function(data) {
					// always executed
					console.log(data.data);
					if (data.code == 200) { 
						
						self.userlist = data.data.user_list;
					}
					else if (data.code == 101) {
						//登录超时
						location.href = "index.html";
					}
					else if (data.code == 403) {
						 // Vue.toasted.error( '没有权限', {
						 // 	position: 'top-center',
						 // 	duration: '2000',
						 // })
						 
						 self.$router.push({ name: '403'});
						 
						 return;
					}
					else{
						self.$toasted.error( data.message, {
							position: 'top-center',
							duration: '2000',
						})
					}
				});
				//END
				
			},
			
			
			init(callback) {
				let self = this;
			
				//
				this.axios.get(this.GLOBAL.baseURI + 'getDepartmentList', {
						params: {
							token: self.token,
							// dept_id: self.dept_id,
							// query: self.query,
							// current_page: self.current_page,
							// page_size: self.page_size,
						}
					})
					.then(function(response) {
						console.log(response);
						return response.data;
					})
					.catch(function(error) {
						console.log(error);
					})
					.then(function(data) {
						// always executed
						console.log(data.data);
						if (data.code == 200) {
							self.departmentlist = data.data.departments;
							
							for (let i = 0; i < self.departmentlist.length; i++) {
								let dept = self.departmentlist[i];
								self.deptmap[dept.id] = dept;
							}
							console.log('--- dept map ->', self.deptmap);
							
							self.current_date = data.data.current_date;
							
							if (typeof callback == 'function') {
							    callback()
							}
						} 
						else if (data.code == 101) {
							//登录超时
							// location.href = "index.html";
							self.logoff();
						} 
						else if (data.code == 403) {
							//没有权限 
							self.$toasted.error( '没有权限', {
								position: 'top-center',
								duration: '2000',
							})
						} 
						else {
							self.$toasted.error( data.message, {
								position: 'top-center',
								duration: '2000',
							})
						}
					});
				//END
			
			},
			
			edit(index){
				console.log(index);
				let self = this;
				
				let user = self.userlist[index];
				user.password = '';
				
				user.disabled = !user.enabled;
				
				self.selectedIndex = index;
				self.user = Object.assign({},user);
				// self.user.role_name = user.dept_id==0?'ROLE_ADMIN':'ROLE_USER';
				self.user.role_name = 'ROLE_USER';//只能添加B
				self.user.dept_id = user.dept_id;
				
				console.log(self.selectedIndex,self.user);
			},
			
			save(){
				let self = this;
				console.log(self.user);
				// return;
				
				//校验
				if(!self.user.role_name){
					self.$toasted.error( '请选择用户类型', {
						position: 'top-center',
						duration: '2000',
					})
					return;
				}
				
				if(self.user.role_name == 'ROLE_USER' && self.user.dept_id == 0){
					self.$toasted.error( '请选择企业', {
						position: 'top-center',
						duration: '2000',
					})
					return;
				}
				
				if(self.selectedIndex == -1 && !self.user.password){
					self.$toasted.error( '密码不能为空', {
						position: 'top-center',
						duration: '2000',
					})
					return;
				}
				
				if(!self.user.phone_number && !self.user.email ){
					self.$toasted.error( '手机或邮箱至少填写一个', {
						position: 'top-center',
						duration: '2000',
					})
					return;
				}
				
				self.disabled = true;
				
				// 组装数据
				let postData = {
					token: self.token,
					user_id: self.selectedIndex!=-1?self.user.id:'',
					dept_id: self.user.dept_id,
					role_name: self.user.role_name,
					phone_number: self.user.phone_number,
					email: self.user.email,
					password: self.user.password!=undefined?self.user.password:'',
					enabled: self.user.disabled?0:1,
				}
				
				self.axios.get(self.GLOBAL.baseURI + 'saveEntUser', {
					params: postData
				})
				.then(function(response) {
					console.log(response);
					return response.data;
				})
				.catch(function(error) {
					console.log(error);
				})
				.then(function(data) {
					// always executed
					console.log(data.data);
					
					self.disabled = false;
					
					if (data.code == 200) { 
						
						if(data.data.result_code == 'success' ){
							let user = data.data.user;
							user.disabled = !user.enabled;
							
							if(self.selectedIndex==-1){
								self.userlist.unshift(user);
							}
							else{
								self.userlist.splice(self.selectedIndex,1,user);
							}
							
							self.$toasted.success( '保存成功', {
								position: 'top-center',
								duration: '2000',
							})
							
							self.clear();
						}
						else{
							self.$toasted.error( data.data.result_msg, {
								position: 'top-center',
								duration: '2000',
							})
						}
					}
					else if (data.code == 3007) {
						self.$toasted.error( '帐号已存在，请修改', {
							position: 'top-center',
							duration: '2000',
						})
					}
					
					else if (data.code == 403) {
						 // Vue.toasted.error( '没有权限', {
						 // 	position: 'top-center',
						 // 	duration: '2000',
						 // })
						 
						 self.$router.push({ name: '403'});
						 
						 return;
					}
					
					else if (data.code == 101) {
						//登录超时
						location.href = "index.html";
					}
					else{
						self.$toasted.error( data.message, {
							position: 'top-center',
							duration: '2000',
						})
					}
				});
			},
			
			
			remove(){
				let self = this;
				console.log(self.user);
				// return;
				
				self.disabled = true;
				
				// 组装数据
				let postData = {
					token: self.token,
					user_id: self.selectedIndex!=-1?self.user.id:''
				}
				
				self.axios.get(self.GLOBAL.baseURI + 'deleteEntUser', {
					params: postData
				})
				.then(function(response) {
					console.log(response);
					return response.data;
				})
				.catch(function(error) {
					console.log(error);
				})
				.then(function(data) {
					// always executed
					console.log(data.data);
					
					self.disabled = false;
					
					if (data.code == 200) { 
						for(let i=0; i<self.userlist.length; i++){
							if(self.selectedIndex == i){
								self.userlist.splice(i,1);
							}
						}
						
						self.$toasted.success( '删除成功', {
							position: 'top-center',
							duration: '2000',
						})
						
						self.clear();
					}
					else if (data.code == 403) {
						 // Vue.toasted.error( '没有权限', {
						 // 	position: 'top-center',
						 // 	duration: '2000',
						 // })
						 
						 self.$router.push({ name: '403'});
						 
						 return;
					}
					else if (data.code == 101) {
						//登录超时
						location.href = "index.html";
					}
					else{
						self.$toasted.error( data.message, {
							position: 'top-center',
							duration: '2000',
						})
					}
				});
			},
			
			clear() {
				var self = this;
				
				self.selectedIndex = -1;
				self.user = {
					disabled: false,
					phone_number: '',
					email: '',
					password: '',
					dept_id: 0,
					role_name: 'ROLE_USER',
				};
			},
			
			onOutletChangeListener(e){
				let self = this;
				console.log('--- outlet ID->',e)
				self.outlet_id = e;
				
				self.init(() => {
					self.initData();
					self.clear();
				});
				
			},
		},
	}
</script>

<style>
</style>
